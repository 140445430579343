import React from 'react';

import { Card, Grid, Select, Input, Box, FormControl, 
  Checkbox, ListItemText, Chip, MenuItem 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  filterFieldsContainer: {
    marginTop: theme.spacing(1),
  },
  filterFieldContainer: {
    padding: theme.spacing(1),
  },
  filterFieldLabel: {
    fontWeight: 600,
  },
  formControl: {
    width: "100%"    
  },
  formControlText: {
    fontWeight: 600,
    paddingLeft: theme.spacing(1)
  },
  chipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FilterField = ({ value, label, values, name, filter, setFilter }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    /**
     * Name is the field name, e.g. age or breed
     * value is the field value, e.g. ["0 to 6 months", "8 years and over"]
     */
    const { name, value } = event.target;
    const updated = { ...filter };
    updated[name] = value;
  
    // Sex can only be male, female or no filter
    if (name === "sex" && updated[name].length > 1) {
      updated[name].shift();
    }
  
    setFilter(updated);
  }

  return (
    <Grid item xs={12} md={4}>
      <Card className={classes.filterFieldContainer}>
        <Grid container item>
          <FormControl className={classes.formControl}>
            <Select
              multiple
              value={value}
              onChange={handleChange}
              name={name}
              input={<Input />}
              renderValue={() => (
                <Grid container justify="flex-start" >
                  <Box variant="body2" fontWeight={600} className={classes.formControlText}>{label}</Box>
                </Grid>
              )}
              displayEmpty={true}
              MenuProps={MenuProps}
            >
              {values.map((v) => (
                <MenuItem key={v} value={v}>
                  <Checkbox checked={value.indexOf(v) > -1} />
                  <ListItemText primary={v} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Card>
    </Grid>
  )
};

export default function DogFilterFields({ filter, setFilter, appData }) {
  const classes = useStyles();
  const { breeds, sexes, ages } = appData.dog;

  if (!breeds || !sexes || !ages) return null;

  const handleChipClick = (name, value) => {
    /**
     * Name is the field name, e.g. age or breed
     * value is the field value, e.g. ["0 to 6 months", "8 years and over"]
     */
    const updated = { ...filter };
    updated[name] = updated[name].filter(f => f !== value);
    setFilter(updated);
  }

  const FilterChip = ({ name, value}) => (
    <Chip 
      label={value} 
      className={classes.chip}
      onDelete={() => handleChipClick(name, value)}
    />
  );

  const Chips = () => (
    <Grid item container spacing={3} className={classes.chipsContainer}>
      {[
        ...filter.age.map(v=> (<FilterChip key={v} name={"age"} value={v}/>)),
        ...filter.breed.map(v => (<FilterChip key={v} name={"breed"} value={v}/>)),
        ...filter.sex.map(v => (<FilterChip key={v} name={"sex"} value={v}/>)),
      ]}
    </Grid>      
  );

  return (
    <Grid container spacing={3} className={classes.filterFieldsContainer}>    
      <FilterField
        value={filter.breed}
        values={breeds}
        label="Breed"
        name="breed"
        filter={filter}
        setFilter={setFilter}
      />
      <FilterField 
        value={filter.age}
        values={ages}
        label="Age"
        name="age"
        filter={filter}
        setFilter={setFilter}
      />
      <FilterField 
        value={filter.sex}
        values={sexes}
        label="Sex"
        name="sex"
        filter={filter}
        setFilter={setFilter}
      />

      <Chips/>

    </Grid>
  );
}