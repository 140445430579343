import React, { useEffect, useState, cloneElement } from 'react';
import { useHistory } from 'react-router';
import { Route, Switch, withRouter } from 'react-router-dom';

import Amplify, { Auth } from 'aws-amplify';

import SignIn from './SignIn';
import SignUp from './SignUp';
import ConfirmSignUp from './ConfirmSignUp';
import SignUpSuccess from './SignUpSuccess';
import ConfirmForgotPassword from './ConfirmForgotPassword';
import ForgotPassword from './ForgotPassword';

const Authentication = ({ config, location, children }) => {
  const { push } = useHistory();

  const [user, setUser] = useState(null);
  const [authState, setAuthState] = useState({
    status: "signedOut"
  });
  const [preAuthPath, setPreAuthPath] = useState("/");
  const [logOut, setLogOut] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const validAuthRoutes = [
    "/auth/signin",
    "/auth/signup",
    "/auth/signup/success",
    "/auth/signup/confirm",
    "/auth/forgotpassword",
    "/auth/forgotpassword/confirm",
  ];

  const handleLogOut = async () => {
    // Loug out the user and send them back to the sign in page
    try {
      setUser(null);
      await Auth.signOut();
      setLogOut(false);
      setAuthState({
        status: "signedOut"
      });
      window.location = "/auth/signin";
    } catch (error) {
      console.error('error signing out: ', error);
    }
  };

  const loadFromConfig = async _ => {
    /**
     * Init the app from the public env.json file
     * After loaded, check to see if a user is logged in
     */
    try {
      await Amplify.configure({
        Auth: {
          region: config.cognito.region,
          userPoolId: config.cognito.userPoolId,
          userPoolWebClientId: config.cognito.appClient,
        },
        API: {
          endpoints: [
            {
              name: config.application,
              endpoint: config.api,
              custom_header: async () => { 
                return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
              }
            }
          ]
        }
      });
      checkLogin();
      setLoaded(true);
    } catch (err) {
      console.error(err);
    }
  }

  const transformUserClaims = (payload) => {
    return {
      id: payload.id,
      customerId: payload.customerId,
      authId: payload.sub,
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      status: payload.status,
      expiry: payload.expiry,
    }
  };

  const checkLogin = async () => {
    /**
     * Get the details for the logged in user and save to user state.
     * 
     * If the user is not logged in, save the URL they went to and push
     * them to the sign in screen.
     * 
     * If the user is logged in, push them to either the URL they originally
     * went to or root.
     */
    try {
      const user = await Auth.currentAuthenticatedUser();
      const payload = user.signInUserSession.idToken.payload;
      setUser(transformUserClaims(payload));
      const redirect = (preAuthPath.startsWith("/auth")) ? "/" : preAuthPath;
      push(redirect);
    } catch (err) {
      setUser(null);

      // Handle expected "not authenticated" error
      if (err !== "The user is not authenticated") {
        console.error(err);
        throw err;
      }
      
      // If this is not a valid auth route push to signin, but save
      // URL for redirection later
      if (!validAuthRoutes.includes(location.pathname)) {
        setPreAuthPath(location.pathname);
        push("/auth/signin");
      }
    }
  }

  const refreshLogin = async (callback) => {        
    const session = await Auth.currentSession();       
    const refresh = await session.getRefreshToken();
    const user = await Auth.currentAuthenticatedUser();     
    await user.refreshSession(refresh, (err, session) => {    
      const payload = user.signInUserSession.idToken.payload;       
      setUser(transformUserClaims(payload));
      // Array where first index is a function and rest are params
      if (callback) {
        const funct = callback.shift();
        funct.apply(null, callback)
      }
    });
  };

  useEffect(() => {
    if (config) loadFromConfig();
  }, [config]);

  useEffect(() => {
    if (logOut) handleLogOut();
  }, [logOut, loaded]);

  useEffect(() => {
    if (!user) checkLogin();
  }, [authState, config, loaded]);

  // Process query string to check for logout
  const query = location.search;
  const params = {};
  let loggingOut = false;
  if (query[0] === "?") {
    query.substring(1).split("&").forEach(p => {
      const pair = p.split("=");
      params[pair[0]] = pair[1];
    });
    if (params.logout && !logOut) {
      setLogOut(true);
      loggingOut = true;
    }
  }

  // If a user is logged in, return the app
  if (user && !loggingOut && !logOut) {   
    return (
      <>{cloneElement(children, { user, refreshLogin })}</>
    );
  }

  // Otherwise return auth
  // All paths other than specific auth path redirect to signin if the user is signed out
  if (!config || !loaded || loggingOut) return null;
  return (
    <>
      <Switch>
        <Route exact path="/auth/signin"
          render={(props) => (
            <SignIn {...props} authState={authState} setAuthState={setAuthState} params={params}/>
          )} 
        />
        <Route exact path="/auth/signup"
          render={(props) => (
            <SignUp {...props} authState={authState} setAuthState={setAuthState} params={params}/>
          )} 
        />
        <Route exact path="/auth/signup/success"
          render={(props) => (
            <SignUpSuccess {...props} authState={authState} setAuthState={setAuthState} params={params}/>
          )} 
        />
        <Route exact path="/auth/signup/confirm"
          render={(props) => (
            <ConfirmSignUp {...props} authState={authState} setAuthState={setAuthState} params={params}/>
          )} 
        />
        <Route exact path="/auth/forgotpassword"
          render={(props) => (
            <ForgotPassword {...props} authState={authState} setAuthState={setAuthState} params={params}/>
          )} 
        />
        <Route exact path="/auth/forgotpassword/confirm"
          render={(props) => (
            <ConfirmForgotPassword {...props} authState={authState} setAuthState={setAuthState} params={params}/>
          )} 
        />
        <Route path="/" 
          render={(props) => (
            <SignIn {...props} authState={authState} setAuthState={setAuthState} params={params}/>
          )} 
        />
      </Switch>
    </>
  );
}
export default withRouter(Authentication);