import React, { useEffect, useState } from 'react';

import { Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';

import { API } from 'aws-amplify';

import CatListItem from './CatListItem';
import Cat from './Cat';

import LoadingButton from '../Layout/LoadingButton';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  count: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  }, 
}));

export default function Cats({ filter }) {
  const classes = useStyles();

  const [cats, setCats] = useState(null);
  const [selectedCat, setSelectedCat] = useState(null);
  
  const numResults = 15;

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(null);
  const [count, setCount] = useState(null);

  const [loading, setLoading] = useState(true);

  const getCats = async (page, filter) => { 
    try {
      setLoading(true);

      const data = { 
        body: { filter }, 
        queryStringParameters: { page, numResults }
      };
      const url = `/cats`;
      const response = await API.post('rescuepaws', url, data);

      setCats(response.data);
      setPages(+response?.pagination?.pages);
      setPage(+response?.pagination?.page);
      setCount(+response?.pagination?.count);

      console.log(response.data);

      setLoading(false);
    } catch (err) {
      console.error("error making API request", err);
      setLoading(false);
    }
  }

  useEffect(() => {
    getCats(page, filter);
    setPage(1);
  }, [filter]);

  const onPageChange = async (event, value) => {
    if (value !== page) {
      setPage(value);
      await getCats(value, filter);
    } 
  };

  const selectCat = (cat) => {
    setSelectedCat(cat);
    console.log("selcted")
    console.log(cat)
  };

  const unselectCat = () => {
    setSelectedCat(null);
  };

  const Cats = () => (
    <Grid container spacing={4}>
      {cats.map((cat) => (
        <CatListItem 
          key={cat._id} 
          cat={cat}
          selectCat={() => selectCat(cat)}
        />
      ))}
    </Grid>
  );

  const PaginationControl = ({ pages, page, onPageChange }) => (
    <Grid item>

      {/* Small screen pagination */}
      <Hidden mdUp>
        <Pagination 
          count={pages} 
          size="small"
          variant="outlined" 
          onChange={onPageChange}
          page={page}
          boundaryCount={2}
          siblingCount={0}
        />
      </Hidden>

      {/* Larger screen pagination */}
      <Hidden smDown>
        <Pagination 
          count={pages} 
          size="large"
          variant="outlined" 
          onChange={onPageChange}
          page={page}
          boundaryCount={2}
          siblingCount={1}
        />
      </Hidden>

    </Grid>
  );

  if (loading) return (
    <LoadingButton type="screen"/>
  );

  if (selectedCat) return (
    <Grid container direction="column" xs={12}>
      <Cat 
        cat={selectedCat} 
        close={unselectCat}
      />
    </Grid>
  );

  if (!cats) return (
    <Grid container direction="column">
      <Typography variant="h5" color="primary" className={classes.title}>
        Cats
      </Typography>
      <Typography variant="body1" className={classes.count}>
        No cats found
      </Typography>
    </Grid>
  );

  return (
    <>
      <Grid container direction="column">
        <Typography variant="h5" color="primary" className={classes.title}>
          Rescue Cats
        </Typography>
        <Typography variant="body1" className={classes.count}>
          Showing {1 + ((page - 1) * numResults)} to {numResults + ((page - 1) * numResults)} of <strong>{count}</strong> cats
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Cats />
      </Grid>
      <Grid container item xs={12} justify="center">
        <PaginationControl
          page={page}
          pages={pages}
          onPageChange={onPageChange}
        />
      </Grid>
    </>
  );
}