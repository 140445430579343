import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  screen: {
    marginTop: theme.spacing(4)
  },
}));

export default function LoadingButton({ type="button" }) {
  const classes = useStyles();

  if (type === "button") return (
    <CircularProgress size={24} className={classes[type]} />
  );

  if (type === "screen") return (
    <Grid container justify="center">
      <CircularProgress size={128} className={classes[type]} color={"primary"}/>
    </Grid>
  );

  return null;
}