import React, { useState } from 'react';

import { Grid, Typography, Button, Card, Hidden, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close, Favorite, FavoriteBorder  } from '@material-ui/icons';

import { API } from 'aws-amplify';

import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: "auto"
  },
  close: {
    float: "right",
    cursor: "pointer"
  },
  catButton: {
    margin: theme.spacing(1),
  },
  addFavouriteButton: {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.secondary.light,
    }
  },
  removeFavouriteButton: {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.dark,
    }
  },
  name: {
    textAlign: "center",
    paddingBottom: theme.spacing(1),
  },
  image: {
    width: "100%"
  },
  restrictedField: {
    maxWidth: "290px"
  }
}));

export default function Cat({ cat, setCat, close }) {
  const classes = useStyles();
  const [favLoading, setFavLoading] = useState(false);
  if (!cat) return null;
  const { created, name, images, url, breed, ageLabel, sex, organisation, location, favourite } = cat;

  console.log(created)

  const date = new Date(created);
  const dateAdded = format(date, 'dd/MM/yyyy');

  const addFavourite = async () => {
    try {
      setFavLoading(true);

      const data = { 
        body: { type: "cats", id: cat.id }, 
      };
      const url = `/favourites/add`;
      await API.put('rescuepaws', url, data);

      const updated = { ...cat };
      updated.favourite = true;
      setCat(cat._id, updated);

      setFavLoading(false);
    } catch (err) {
      console.error("error making API request", err);
      setFavLoading(false);
    }
  }

  const removeFavourite = async () => {
    try {
      setFavLoading(true);

      const data = { 
        body: { type: "cats", id: cat.id }, 
      };
      const url = `/favourites/remove`;
      await API.put('rescuepaws', url, data);

      const updated = { ...cat };
      updated.favourite = true;
      setCat(cat._id, updated);

      setFavLoading(false);
    } catch (err) {
      console.error("error making API request", err);
      setFavLoading(false);
    }
  }

  return (
    <Card className={classes.root}>

      <Grid container item justify="flex-end">
        <Grid item>
          <Close className={classes.close} onClick={close}/>
        </Grid>
      </Grid>

      <Grid container item direction="column" justify="center" alignItems="center">

      <Grid item>
          <Typography component="h2" variant="h5" noWrap className={classes.name} >
            {name}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <img
            src={images[0]} 
            title={name}
            className={classes.image}
            alt={name}
          />
        </Grid>

        <Grid item>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={() => window.open(url)}
            className={classes.catButton}
          >
            Take me to this cat!
          </Button>
        </Grid>

        <Grid item>
          {favLoading && (
            <CircularProgress size={24} />
          )}
          {!favLoading && favourite && (
            <Favorite 
              onClick={removeFavourite}
              className={classes.removeFavouriteButton}
              fontSize="large"
              cursor="pointer"
            />
          )}
          {!favLoading && !favourite && (
            <FavoriteBorder
              onClick={addFavourite}
              className={classes.addFavouriteButton}
              fontSize="large"
              cursor="pointer"
            />
          )}
        </Grid>

        <Hidden smUp>
          <Grid item>
            <Typography variant="subtitle1" noWrap className={classes.restrictedField}>
              <strong>Breed:</strong> {breed}
            </Typography>
          </Grid>
        </Hidden>
        <Hidden xsDown>
          <Grid item>
            <Typography variant="subtitle1" noWrap>
              <strong>Breed:</strong> {breed}
            </Typography>
          </Grid>
        </Hidden>

        <Grid item>
          <Typography variant="subtitle1" noWrap>
          <strong>Age:</strong> {ageLabel}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="subtitle1" noWrap>
          <strong>Sex:</strong> {sex}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="subtitle1" noWrap className={classes.restrictedField}>
          <strong>Rescue Charity:</strong> {organisation?.name || "Unknown"}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="subtitle1" noWrap className={classes.restrictedField}>
          <strong>Location:</strong> {location || "Unknown"}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="subtitle1" noWrap>
          <strong>Added:</strong> {dateAdded}
          </Typography>
        </Grid>

      </Grid>
    </Card>
  );
}
