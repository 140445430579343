import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import Authentication from '../Authentication';
import Payment from '../Payment';
import RescuePaws from '../RescuePaws';

/*
   Colours:
   ffffff - White
   000000 - Black
   191e22 - Not Black
   303137 - Grey
   15552e - Green
   153D56 - Blue
   1A4E6D - Blue Highlight
   561535 - Purple / Red
   561552 - Contrast Purple
   6D1A68 - Contrast Purple Highlight
*/

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#15552e",
    },
    secondary: {
      main: "#561552"
    },
    tertiary: {
      main: '#153D56',
    },
  },
  typography: {
    fontFamily: [
      'Verdana', 
      'sans-serif',
    ].join(','),
  }
});

const App = _ => {
  /**
   * Responsible for loading the application config. Passes Payment and RescuePaws into Authentication.
   * Authentication either handles user auth if there is no logged in user, or passes control
   * to Payment and then to RescuePaws.
   */
  const [config, setConfig] = useState(null);

  const load = async () => {
    /**
     * Init the app from the public env.json file
     * After loaded, check to see if a user is logged in
     */
    try {
      const response = await fetch("/env.json");
      const text = await response.text();
      const config = JSON.parse(text);
      setConfig(config);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (!config) load();
  });

  if (!config) return null; // TODO Add loading
  return (
    <>
      <ThemeProvider theme={theme}>
        <Authentication config={config}>
          <Payment config={config}>
            <RescuePaws />
          </Payment>
        </Authentication>
      </ThemeProvider>
    </>
  );
};
export default withRouter(App);