import React from 'react';

import { Grid, Card, CardActionArea, CardContent, Typography, CardMedia, CardActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(1),
  },
  amount: {
    marginBottom: theme.spacing(2),
  }
}));

export default function ProductItem({ product, showDescription=true, showTitle=true, showImages=true }) {
  const classes = useStyles();

  const { active, description, images: productImages, name, prices } = product;
  const price = prices[0];
  const images = productImages || [];
  const { unit_amount } = price;

  if (!product || !active || !price) return null;
  const amount = `£${(unit_amount/100).toString()}`;

  const Title = () => {
    return showTitle ? (
      <Typography 
        variant="h6" 
        align="center" 
        fontWeight="fontWeightStrong"
      >
        {name}
      </Typography>
    ) : null;
  };

  const Description = () => {
    return showDescription ? (
      <Typography 
        variant="body2" 
        color="textSecondary" 
        align="center" 
        className={classes.description}
      >
        {description}
      </Typography>
    ) : null;
  };

  const Images = () => {
    if (!images || images.length < 1 || !showImages) return null;
    return (
      <CardMedia
        component="img"
        alt={name}
        height={120}
        image={images[0]}
        title={name}
      />
    );
  };

  const Price = () => (
    <Grid container justify="center">
      <Typography 
        color="primary" 
        component="h1" 
        className={classes.amount} 
        fontWeight="fontWeightBold"
      >
        {amount}
      </Typography>
    </Grid>
  );

  return (
    <Grid item>
      <Card>
        <CardActionArea>
          <Images/>
          <CardContent>
            <Title />
            <Description />
          </CardContent>
          <CardActions>
            <Price />
          </CardActions>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
