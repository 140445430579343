import React from 'react';

import { Grid, Paper, Typography, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  section: {
    padding: theme.spacing(2),
  },
  sectionInfo: {
    marginBottom: theme.spacing(2),
  },
}));

const transformStatus = (txt) => {
  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
};

export default function Profile({ user }) {
  const classes = useStyles();

  const { email, firstName, lastName, expiry: expiryIso, status: userStatus } = user;

  const expiryDate = new Date(expiryIso);
  const expires = format(expiryDate, 'dd/MM/yyyy HH:mm');

  const status = transformStatus(userStatus);

  const Field = ({ label, value }) => (
    <TextField
      label={label}
      fullWidth
      defaultValue={value}
      variant="outlined"
      InputProps={{
        readOnly: true,
      }}
    />
  );

  return (
    <Paper className={classes.root}>
      <Grid container direction={"column"} spacing={2}>
        <Grid item container justify="space-between">
          <Grid item>
            <Typography variant="h5" color="primary">
              Account
            </Typography>
          </Grid>
          <Grid item>
            <Button 
              variant="contained" 
              color="secondary"
              onClick={() => window.location = '/?logout=true'}
            >
              Logout
            </Button>
          </Grid>
        </Grid>
        <Grid item className={classes.sectionInfo}>
          <Paper className={classes.section}>
            <Grid container direction={"column"} spacing={3} >
              <Grid item>
                <Typography variant="h5">
                  Personal
                </Typography>
              </Grid>
              <Grid item container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Field label="First Name" value={firstName} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field label="Last Name" value={lastName} />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Field label="Email" value={email}/>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={classes.section}>
            <Grid container direction={"column"} spacing={3} >
              <Grid item>
                <Typography variant="h5">
                  Subscription
                </Typography>
              </Grid>
              <Grid item container spacing={3} className={classes.heading}>
                <Grid item xs={12} md={6}>
                  <Field label="Status" value={status} className={classes.status}/>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field label="Expires" value={expires} />
                </Grid>              
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}
