import React, { useEffect, useState } from 'react';

import { Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';

import { API } from 'aws-amplify';

import DogListItem from './DogListItem';
import Dog from './Dog';

import LoadingButton from '../Layout/LoadingButton';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  count: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  }, 
}));

const DogsList = ({ setSelectedDog, dogs }) => {
  return (
    <Grid container spacing={4}>
      {dogs.map((dog) => (
        <DogListItem 
          key={dog._id} 
          dog={dog}
          selectDog={() => setSelectedDog(dog)}
        />
      ))}
    </Grid>
  )
};

export default function Dogs({ filter }) {
  const classes = useStyles();

  const [dogs, setDogs] = useState(null);
  const [selectedDog, setSelectedDog] = useState(null);
  
  const numResults = 15;

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(null);
  const [count, setCount] = useState(null);

  const [loading, setLoading] = useState(true);

  const getDogs = async (page, filter) => { 
    try {
      setLoading(true);

      const data = { 
        body: { filter }, 
        queryStringParameters: { page, numResults }
      };
      const url = `/dogs`;
      const response = await API.post('rescuepaws', url, data);

      setDogs(response.data);
      setPages(+response?.pagination?.pages);
      setPage(+response?.pagination?.page);
      setCount(+response?.pagination?.count);

      setLoading(false);
    } catch (err) {
      console.error("error making API request", err);
      setLoading(false);
    }
  }

  useEffect(() => {
    getDogs(page, filter);
    setPage(1);
  }, [filter]);

  const onPageChange = async (event, value) => {
    if (value !== page) {
      setPage(value);
      await getDogs(value, filter);
    } 
  };

  const unselectDog = () => {
    setSelectedDog(null);
  };

  const PaginationControl = ({ pages, page, onPageChange }) => (
    <Grid item>

      {/* Small screen pagination */}
      <Hidden mdUp>
        <Pagination 
          count={pages} 
          size="small"
          variant="outlined" 
          onChange={onPageChange}
          page={page}
          boundaryCount={2}
          siblingCount={0}
        />
      </Hidden>

      {/* Larger screen pagination */}
      <Hidden smDown>
        <Pagination 
          count={pages} 
          size="large"
          variant="outlined" 
          onChange={onPageChange}
          page={page}
          boundaryCount={2}
          siblingCount={1}
        />
      </Hidden>

    </Grid>
  );

  if (loading) return (
    <LoadingButton type="screen"/>
  );

  const setDog = (id, dog) => {
    const updated = dogs.map(d => {
      if (d._id === id) return dog;
      return d;
    });
    setDogs(updated);
  }

  if (selectedDog) return (
    <Grid container item direction="column" xs={12}>
      <Dog 
        dog={selectedDog} 
        close={unselectDog}
        setDog={setDog}
      />
    </Grid>
  );

  if (!dogs) return (
    <Grid container direction="column">
      <Typography variant="h5" color="primary" className={classes.title}>
        Dogs
      </Typography>
      <Typography variant="body1" className={classes.count}>
        No dogs found
      </Typography>
    </Grid>
  );

  return (
    <>
      <Grid container direction="column">
        <Typography variant="h5" color="primary" className={classes.title}>
          Rescue Dogs
        </Typography>
        <Typography variant="body1" className={classes.count}>
          Showing {1 + ((page - 1) * numResults)} to {numResults + ((page - 1) * numResults)} of <strong>{count}</strong> dogs
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DogsList dogs={dogs} setSelectedDog={setSelectedDog} />
      </Grid>
      <Grid container item xs={12} justify="center">
        <PaginationControl
          page={page}
          pages={pages}
          onPageChange={onPageChange}
        />
      </Grid>
    </>
  );
}