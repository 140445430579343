import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import { Button, TextField, CircularProgress, Grid, Link} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Wrapper } from '../Layout';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
  autoSubmit: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ConfirmSignUp({ authState, setAuthState, params }) {
  const classes = useStyles();
  const { push } = useHistory();

  const initEmail = params.email || authState.email || "";
  const initCode = params.code || authState.code || "";

  const [autoSubmit, setAutoSubmit] = useState(initEmail && initCode);
  const [showEmail, setShowEmail] = useState(!initEmail);
  const [showCode, setShowCode] = useState(!initCode); 
  const [email, setEmail] = useState(initEmail);
  const [code, setCode] = useState(initCode);

  const [error, setError] = useState("");

  const onCodeChange = async (event) => {
    const code = event.target.value;
    setCode(code);
  };

  const onEmailChange = async (event) => {
    const email = event.target.value;
    setEmail(email);
  };

  const handleError = (err) => {
    const validCode = [
      "UserNotFoundException",
      "CodeMismatchException",
      "InvalidData",
      "NotAuthorizedException",
    ].includes(err.code);

    if (err.code === "UserNotFoundException") {
      err.message = "User not found";
    }

    if (err.code === "NotAuthorizedException") {
      err.message = "User is already confirmed";
      gotoSignIn("AlreadyConfirmed");
    }
    
    setAutoSubmit(false);
    setShowEmail(true);
    setShowCode(true);

    console.error(err);
    if (validCode) {
      setError(err.message);
    } 
    else throw err;
  };

  const validate = (email, code) => {
    const err = {
      code: "InvalidData",
      message: "An email and code must be provided"
    };
    if (!email || !code) {
      handleError(err);
      throw err;
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    validate(email, code);
    await confirmSignUp(email, code);
  };

  const confirmSignUp = async (email, code) => {
    try {
      await Auth.confirmSignUp(email, code);
      setAuthState({
        ...authState,
        status: "confirmedSignup"
      });
      gotoSignIn();
    }
    catch (err) {
      handleError(err);
    }
  }

  const gotoSignIn = (error) => {
    let url = `/auth/signin`;
    if (email) url += `?email=${email}`;
    if (error) {
      if (!email) url += `?error=${error}`;
      else url += `&error=${error}`;
    } 
    push(url);
  };

  useEffect(() => {
    if (autoSubmit) confirmSignUp(email, code);
  }, []);

  const formView = (
    <form className={classes.form} noValidate>
      <Grid container spacing={2}>

        {showEmail && (
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              name="email"
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email"
              autoFocus
              onChange={onEmailChange}
            />
          </Grid>
        )}

        {showCode && (
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              name="code"
              variant="outlined"
              required
              fullWidth
              id="code"
              label="Code"
              autoFocus
              onChange={onCodeChange}
            />
          </Grid>
        )}

      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={onSubmit}
      >
        Confirm Code
      </Button>
    </form>
  );

  const autoSubmitView = (
    <Grid container spacing={3} className={classes.autoSubmit}>
      <Grid container item justify="center">
        We're just checking your code!
      </Grid>
      <Grid container item justify="center">
        <CircularProgress />
      </Grid>
    </Grid>
  );
  
  return (
    <Wrapper 
      title="Confirm Sign Up"
      error={error}
    >
      {autoSubmit ? autoSubmitView : formView}
      <Grid container justify="flex-end">
        <Grid item>
          <Link onClick={gotoSignIn} variant="body2">
            Already have an account? Sign in
          </Link>
        </Grid>
      </Grid>
    </Wrapper>
  );
}