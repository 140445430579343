import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, CardContent, CardMedia, Typography, } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    height: 500
  },
  cardMedia: {
    height: 380
  },
  name: {
    textAlign: "center"
  },
  breed: {
    textAlign: "center"
  },
  age: {
    textAlign: "center"
  }
}));

export default function CatListItem({ cat, selectCat }) {
  const classes = useStyles();
  if (!cat) return null;
  const { name, images, breed, ageLabel } = cat;
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className={classes.card} onClick={selectCat}>
        <CardMedia 
          className={classes.cardMedia} 
          image={images[0]} 
          title={name} 
        />
        <CardContent>
          <Typography component="h2" variant="h5" noWrap className={classes.name} >
            {name}
          </Typography>
          <Typography variant="subtitle1" noWrap className={classes.breed} >
            {breed}
          </Typography>
          <Typography variant="body2" noWrap className={classes.age} >
            Aged {ageLabel}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}