import React, { useState, useEffect } from 'react';

import { Grid, Paper, Button, TextField, Typography, } from '@material-ui/core';

import { LoadingButton } from '../Layout';
import { API } from 'aws-amplify';

import DogListItem from '../Pets/DogListItem';
import Dog from '../Pets/Dog';

import CatListItem from '../Pets/CatListItem';
import Cat from '../Pets/Cat';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  filterContainer: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  addButton: {
    marginRight: theme.spacing(2),
  },
  name: {
    width: "100%",
  },
  saveButton: {
    background: theme.palette.tertiary.main,
    marginRight: theme.spacing(2),
  },
  
}));

export default function Favourites({ appData }) {
  const classes = useStyles();

  const [favourites, setFavourites] = useState(null);
  const [selectedDog, setSelectedDog] = useState(null);
  const [selectedCat, setSelectedCat] = useState(null);
  const [loading, setLoading] = useState(true);

  const load = async () => {
    try {
      setLoading(true);

      const response = await API.get('rescuepaws', "/favourites");
      setFavourites(response);

      setLoading(false);
    } catch (err) {
      console.error("error making API request", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  if (!favourites) return null;

  const selectDog = (dog) => {
    setSelectedDog(dog);
  };

  const unselectDog = () => {
    setSelectedDog(null);
  };

  const Dogs = () => (
    <Grid container spacing={4}>
      {favourites.dogs.map((dog) => (
        <DogListItem 
          key={dog._id} 
          dog={dog}
          selectDog={() => selectDog(dog)}
        />
      ))}
    </Grid>
  );

  const selectCat = (cat) => {
    setSelectedCat(cat);
  };

  const unselectCat = () => {
    setSelectedCat(null);
  };

  const Cats = () => (
    <Grid container spacing={4}>
      {favourites.cats.map((cat) => (
        <CatListItem 
          key={cat._id} 
          cat={cat}
          selectCat={() => selectCat(cat)}
        />
      ))}
    </Grid>
  );

  if (loading) return (<LoadingButton type="screen"/>);

  if (selectedDog) return (
    <Grid container item direction="column" xs={12}>
      <Dog 
        dog={selectedDog} 
        close={unselectDog}
      />
    </Grid>
  );

  console.log(selectedCat)

  if (selectedCat) return (
    <Grid container item direction="column" xs={12}>
      <Cat 
        cat={selectedCat} 
        close={unselectCat}
      />
    </Grid>
  );

  return (
    <Grid container spacing={4}>
      {!!favourites.cats?.length && (
        <Grid item container direction="column">
          <Typography variant="h5" color="primary" className={classes.title}>
            Favourite Cats
          </Typography>
          <Cats />
        </Grid>
      )}
      {!!favourites.dogs?.length && (
        <Grid item container direction="column">
          <Typography variant="h5" color="primary" className={classes.title}>
            Favourite Dogs
          </Typography>
          <Dogs />
      </Grid>
      )}
    </Grid>
  );
}
