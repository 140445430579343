import React from 'react';
import { useHistory } from 'react-router';

import { Grid, Link, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { Wrapper } from '../Layout';

const useStyles = makeStyles((theme) => ({
  textContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  text: {
    textAlign: "center"
  }
}));

export default function ConfirmSignUp({ authState, setAuthState }) {
  const classes = useStyles();
  const { push } = useHistory();

  const gotoSignIn = _ => {
    push('/auth/signin');
  };

  return (
    <Wrapper 
      title="Account Created"
    >
      <Grid container spacing={3} className={classes.textContainer}> 
        <Grid item>
          <Typography className={classes.text}>
            You have successfully created a Rescue Paws account, but before we
            can complete your subscription and send you notifications about pets
            that you're interested in, we need to validate your email.
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.text}>
            Please check your inbox for a validation link.
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify="flex-end">
        <Grid item>
          <Link onClick={gotoSignIn} variant="body2">
            Already have an account? Sign in
          </Link>
        </Grid>
      </Grid>
    </Wrapper>
  );
}