import React, { useState, } from 'react';
import { useHistory } from 'react-router';

import { Button, TextField, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Wrapper } from '../Layout';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
  autoSubmit: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ConfirmSignUp({ authState, setAuthState, params }) {
  const classes = useStyles();
  const { push } = useHistory();

  const initCode = params.code || authState.code || "";

  const [showCode, setShowCode] = useState(!initCode); 
  const [email, setEmail] = useState("initEmail");
  const [code, setCode] = useState(initCode);
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");

  const onCodeChange = async (event) => {
    const code = event.target.value;
    setCode(code);
  };

  const onEmailChange = async (event) => {
    const email = event.target.value;
    setEmail(email);
  };

  const onPasswordChange = async (event) => {
    const password = event.target.value;
    setPassword(password);
  };

  const handleError = (err) => {
    const validCode = [
      "UserNotFoundException",
      "CodeMismatchException",
      "InvalidData",
    ].includes(err.code);

    if (err.code === "UserNotFoundException") {
      err.message = "User not found";
    }
    
    setShowCode(true);

    console.error(err);
    if (validCode) {
      setError(err.message);
    } 
    else throw err;
  };

  const validate = (email, code, password) => {
    const err = {
      code: "InvalidData",
      message: "An email, code and password must be provided"
    };
    if (!email || !code || !password) {
      handleError(err);
      throw err;
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    validate(email, code, password);
    await confirmPassword(email, code, password);
  };

  const confirmPassword = async (email, code, password) => {
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      gotoSignIn();
    }
    catch (err) {
      handleError(err);
    }
  }

  const gotoSignIn = _ => {
    push(`/auth/signin?email=${email}`);
  };
  
  return (
    <Wrapper 
      title="Change Password"
      error={error}
    >
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              name="email"
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email"
              autoFocus
              onChange={onEmailChange}
            />
          </Grid>

          {showCode && (
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                name="code"
                variant="outlined"
                required
                fullWidth
                id="code"
                label="Code"
                autoFocus
                onChange={onCodeChange}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              name="password"
              variant="outlined"
              required
              fullWidth
              id="password"
              label="New Password"
              autoFocus
              onChange={onPasswordChange}
            />
          </Grid>

        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={onSubmit}
        >
          Change Password
        </Button>
      </form> 
    </Wrapper>
  );
}