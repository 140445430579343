import React from 'react';

import { Box, Typography, Container, CircularProgress, Grid, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

import Copyright from '../Layout/Copyright'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },
  paper: {
    margin: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: "80%",
    height: "auto",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  alert: {
    marginTop: theme.spacing(2),
    maxWidth: "90%",
    minWidth: 0,
    wordBreak: "break-word"
  },
  progressContainer: {
    marginTop: theme.spacing(4),
  },
  progress: {
    color: blue[500],
  },
}));

export default function Wrapper({ title, children, error, loading }) {
  const classes = useStyles();

  const display = (loading) ? "none" : "flex";
  const displayLoading = (loading) ? "flex" : "none";

  const WrapperAlert = () => (
    <Alert 
      severity="error"
      className={classes.alert}
    >
      <Typography>
        {error}
      </Typography>
    </Alert>
  );

  const Logo = () => (
    <img
      className={classes.logo}
      src="/logo.png"
      alt="Rescue Paws"
    />
  );

  const Title = () => (
    <Typography component="h5" className={classes.title}>
      {title.toUpperCase()}
    </Typography>
  );

  const Loading = () => (
    <Box display={displayLoading} className={classes.progressContainer}>
      <Grid container justify="center">
        <Grid item>
          <CircularProgress size={64} className={classes.progress} />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <Paper className={classes.paper}>
        <Logo/>
        <Title/>
        <Loading />
        <Box display={display} flexDirection="column">
          {error && <WrapperAlert/>}
          {children}
        </Box>
      </Paper>
      <Copyright />
    </Container>
  );
};