import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FilterList, Pets } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  filterIconContainers: {
    width: "50px",
    textAlign: "center",
    cursor: "pointer",
  },
}));

export default function FilterMenuBar({ dogsOrCats, setDogsOrCats, showFilterOptions, setShowFilterOptions, }) {
  const classes = useStyles();

  const dogsColor = (dogsOrCats === "dogs") ? "secondary" : "primary";
  const catsColor = (dogsOrCats === "cats") ? "secondary" : "primary";
  const filterColor = (showFilterOptions) ? "secondary" : "primary";

  const FilterIcon = ({ onClick, color, label, pets=true }) => (
    <Grid item className={classes.filterIconContainers} onClick={onClick}>
      {pets && <Pets color={color}/>}
      {!pets && <FilterList color={color}/>}
      <Typography 
        variant="caption" 
        component= "p" 
        color={color}
      >
        {label}
      </Typography>
    </Grid>
  );

  return (
    <Grid item container justify="space-around" direction="row">
      <FilterIcon 
        onClick={() => setDogsOrCats("dogs")}
        color={dogsColor}
        label="Dogs"
      />
      <FilterIcon 
        onClick={() => setDogsOrCats("cats")}
        color={catsColor}
        label="Cats"
      />
      <FilterIcon 
        onClick={() => setShowFilterOptions(!showFilterOptions)}
        color={filterColor}
        label="Filter"
        pets={false}
      />
    </Grid>
  );
}