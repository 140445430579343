import React, { useEffect, useState } from 'react';

import { Paper, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Carousel from 'react-material-ui-carousel';

import ProductItem from './ProductItem';

import { API } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
  productsContainer: {
    marginTop: theme.spacing(2),
  },
  tab: {
    minWidth: 0
  },
}));

export default function ProductList({ setProduct, setLoading }) {
  const classes = useStyles();

  const [products, setProducts] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tabValue, setTabValue] = useState(0);

  const getProducts = async () => { 
    try {
      setLoading(true);
      const products = await API.get('rescuepaws', '/payments/products');
      setProducts(products);
      setProduct(products[selectedIndex]);
      setLoading(false);
    } catch (err) {
      console.error("error making API request", err);
      setLoading(false);
    }
  }

  useEffect(_ => {
    if (!products) getProducts();
  }, []);

  const onTabChange = (event, value) => {
    setTabValue(value);
    setProduct(products[value]);
  };

  const ProductTabs = () => (
    <Tabs 
      value={tabValue} 
      onChange={onTabChange} 
      variant="fullWidth" 
      className={classes.tabs}
    >
      {products.map((p, index) => {
        return (
          <Tab 
            key={index} 
            className={classes.tab} 
            label={p.name} 
            onClick={() => setSelectedIndex(index)}
          />
        )
      })}
    </Tabs>
  );

  if (!products) return null;
  return (
    <Paper className={classes.productsContainer}>
      <ProductTabs />
      <Carousel 
        autoPlay={false} 
        navButtonsAlwaysInvisible={true} 
        index={selectedIndex} 
        indicators={false}
        className={classes.carousel}
      >
        {products.map((product, key) => (
          <ProductItem key={key} product={product} showTitle={false}/>
        ))}
      </Carousel>
    </Paper>
  );
}
