import React, { useState, cloneElement } from 'react';

import { Wrapper } from '../Layout';
import Checkout from './Checkout';
import ProductList from './ProductList';

import { Button, } from '@material-ui/core';

export default function Payment({ user, children, config, refreshLogin }) {

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [selected, setSelected] = useState(null);

  const { status, customerId } = user;

  const onClickSelected = () => {
    setSelected(product);
  };

  const unsetSelected = () => {
    setSelected(null);
    setLoading(true);
  }

  // If a user has paid, return children (app)
  if (status === "subscribed") return (
    <>{cloneElement(children, { user })}</>
  );

  // Otherwise show them subscription options
  // If there is no product selected, show the products screen
  if (!selected) return (
    <Wrapper
      title="Select a Subscription"
      error={error}
      loading={loading}
    >
      <ProductList 
        setProduct={setProduct} 
        setLoading={setLoading}
        setError={setError}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={onClickSelected}
      >
        Select
      </Button>
    </Wrapper>
  );

  // If there is a product selected, show the payment screen
  return (
    <Wrapper
      title="Checkout"
      error={error}
      loading={loading}
    >
      <Checkout
        config={config}
        product={product}
        customerId={customerId}
        refreshLogin={refreshLogin}
        setLoading={setLoading}
        setError={setError}
        goBack={unsetSelected}
      />
    </Wrapper>
  );
};