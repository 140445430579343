import React, { useState, useEffect } from 'react';

import { Grid, Paper, Button, TextField, Typography, } from '@material-ui/core';
import DogFilterFields from '../FilterMenu/DogFilterFields';

import { LoadingButton } from '../Layout';
import { API } from 'aws-amplify';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  filterContainer: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  addButton: {
    marginRight: theme.spacing(2),
  },
  name: {
    width: "100%",
  },
  saveButton: {
    background: theme.palette.tertiary.main,
    marginRight: theme.spacing(2),
  },
  
}));

const Filter = ({ index, filters, setFilters, appData }) => {
  const classes = useStyles();
  const [name, setName] = useState(filters[index].name);

  const setFilter = (filter) => {
    const updated = [...filters];
    updated[index] = filter;
    setFilters(updated);
  };
  
  const onNameChange = (e) => {
    const name = e.target.value;
    setName(name);

    const filter = { ...filters[index] };
    filter.name = name;
    setFilter(filter);
  };

  return (
    <Grid item xs={12} key={index}>
      <Paper className={classes.filterContainer}>
        <TextField
          value={name}
          onChange={onNameChange}
          className={classes.name}
          placeholder="Search Name"
        />
        <DogFilterFields 
          filter={filters[index]}
          setFilter={setFilter}
          appData={appData}
        />
      </Paper>
    </Grid>
  );
};

const Filters = ({ filters, setFilters, appData }) => {
  return filters.map((v, i) => (
    <Filter 
      key={i}
      index={i}
      filters={filters}
      setFilters={setFilters}
      appData={appData}
    />
  ));
};

export default function Searches({ appData }) {
  const classes = useStyles();

  const defaultDogFilter = {
    name: "New Search",
    sex: [], 
    breed: [],
    age: [],
  };

  const [filters, setFilters] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const load = async () => {
    try {
      setLoading(true);

      const response = await API.get('rescuepaws', "/searches");
      const filters = (response?.length) ? response : [defaultDogFilter];
      setFilters(filters);

      setLoading(false);
    } catch (err) {
      console.error("error making API request", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  const handleAdd = () => {
    const updated = [...filters];
    updated.push(defaultDogFilter);
    setFilters(updated);
  };

  const handleSave = async () => {
    try {
      setSaving(true);

      if (filters.length > 3) {
        console.error("Too many filters");

        throw Error("Too many filters");
      }

      const data = { body: { filters }};
      await API.post('rescuepaws', "/searches", data);

      setSaving(false);
    } catch (err) {
      console.error("error making API request", err);
      setSaving(false);
    }
  };

  const Buttons = () => (
    <Grid container justify="flex-end">
      <Grid item>
        {filters.length < 3 && (
          <Button 
            variant="contained" 
            color="secondary"
            className={classes.addButton}
            onClick={handleAdd}
          >
            Add New
          </Button>
        )}
        <Button 
          variant="contained" 
          color="secondary"
          className={classes.saveButton}
          onClick={handleSave}
          disabled={saving}
        >
          Save
          {saving && <LoadingButton />}
        </Button>
      </Grid>
    </Grid>
  );

  if (loading) return (<LoadingButton type="screen"/>);
  return (
    <>
      <Typography variant="h5" color="primary" className={classes.title}>
        Searches
      </Typography>
      <Grid container spacing={4}>
        <Filters filters={filters} setFilters={setFilters} appData={appData}/>
        <Buttons />
      </Grid>
    </>
  );
}
