import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { API } from 'aws-amplify';

import { Button, Box, CircularProgress, Typography } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paymentFormContainer: {
    padding: theme.spacing(2),
  },
  payButton: {
    marginTop: theme.spacing(3),
  },
  payButtonLoading: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  title: {
    marginBottom: theme.spacing(2),
  }
}));

const cardElementOptions = {
  style: {
    base: {
      color: "#32325d",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default function PaymentForm({ customerId, priceId, setError, refreshLogin }) {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const { push } = useHistory();

  const [paying, setPaying] = useState(false);
  const [validCard, setValidCard] = useState(false);

  if (!stripe || !elements ||!customerId || !priceId) return null;

  const makePayment = async (paymentMethod) => {
    const response = await API.post('rescuepaws', '/payments/intent', {
      body: {
        price: priceId,
        customer: customerId,
        paymentMethod,
      }
    });
    if (response.error) throw response.err;
    return response;
  };

  const handlePaymentFailure = (payment) => {
    throw payment;
  }
  
  const createPaymentMethod = async _ => {
    const cardElement = elements.getElement(CardElement);      
    const { error, paymentMethod } = await stripe.createPaymentMethod({      
      type: 'card',
      card: cardElement,
    });
    if (error) throw error;
    return paymentMethod.id;
  };

  const refreshedLogin = () => {
    setPaying(false);
    push("/");
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setPaying(true);

      const paymentMethodId = await createPaymentMethod();
      const payment = await makePayment(paymentMethodId);   

      if (payment.status === 'succeeded') {
        await refreshLogin([refreshedLogin]);
      }
      else handlePaymentFailure(payment);
    } catch (err) {
      console.error(err.message || err);
      setError(err);
      setPaying(false);
    }
  };

  const onCardChange = (event) => {
    if (event.error) {
      setError(event.error.message)
      return;
    }
    if (event.complete) {
      setValidCard(true);
      setError("");
      return;
    }
    setValidCard(false);
  };

  return (
    <Box className={classes.paymentFormContainer}>
      <Typography 
        color="primary" 
        component="h1" 
        fontWeight="fontWeightBold"
        align="center"
        className={classes.title}
      >
        PAYMENT DETAILS
      </Typography>
      <CardElement 
        onChange={onCardChange}
        options={cardElementOptions}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={!stripe || paying || !validCard}
        onClick={handleSubmit}
        className={classes.payButton}
      >
        Pay
        {paying && <CircularProgress size={24} className={classes.payButtonLoading} />}
      </Button>
    </Box>
  );
};