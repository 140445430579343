import React, { useEffect, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import PaymentForm from './PaymentForm';
import PaymentItem from './ProductItem';

import { Box, Card, Grid, Link, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paymentMethodContainer: {
    minWidth: "320px",
  },
  alert: {
    marginTop: theme.spacing(2),
  },
}));

export default function Checkout({ config, product, customerId, refreshLogin, setLoading, goBack }) {
  const classes = useStyles();
  const [stripe, setStripe] = useState(null);
  const stripeKey = config.stripeKey;

  const priceId = product.prices[0].id;

  const [error, setError] = useState(null);
  
  // Load stripe
  const load = async () => {
    setLoading(true);
    const stripe = await loadStripe(stripeKey);
    setStripe(stripe);
    setLoading(false)
  };

  useEffect(() => {
    if (!stripe) load();
  }, []);

  if (!stripe) return null;
  
  const PaymentAlert = () => {
    return (error) ? (
      <Alert 
        severity="error"
        className={classes.alert}
      >
        {error}
      </Alert>
    ) : null;
  };

  const GoBackLink = () => (
    <Grid container justify="flex-end">
      <Grid item>
        <Link onClick={goBack} variant="body2">
          Back to subscriptions
        </Link>
      </Grid>
    </Grid>
  );

  return (
    <>
      <PaymentItem product={product} showDescription={false}/>
      <Card className={classes.container}>
        <PaymentAlert />
        <Box className={classes.paymentMethodContainer}>
          <CssBaseline/>
          <Elements stripe={stripe}>
            <PaymentForm
              customerId={customerId}
              priceId={priceId}
              setError={setError}
              refreshLogin={refreshLogin}
            />
          </Elements>
        </Box>
      </Card>
      <GoBackLink/>
    </>
  );
};