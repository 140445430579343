import React, { useState } from 'react';

import {  Button, Accordion, AccordionDetails, 
  Divider, AccordionActions, Hidden, 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FilterMenuBar from './FilterMenuBar';
import DogFilterFields from './DogFilterFields';

const useStyles = makeStyles((theme) => ({
  accordion: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
}));

export default function FilterMenu({ dogsOrCats, setDogsOrCats, appData, filter, setFilter }) {
  /**
   * filter and setFilter are passed in so that the state is maintained between loads / applies
   */
  const classes = useStyles();
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [internalFilter, setInternalFilter] = useState(filter);

  const apply = () => {
    setFilter(internalFilter)
  };
  
  return (
    <>
      <FilterMenuBar 
        dogsOrCats={dogsOrCats} 
        setDogsOrCats={setDogsOrCats}
        showFilterOptions={showFilterOptions}
        setShowFilterOptions={setShowFilterOptions}
      />

      <div className={classes.accordion}>
        <Accordion expanded={showFilterOptions}>
          <Hidden xsUp><div>Filter</div></Hidden>
          <AccordionDetails>
            {dogsOrCats === "dogs" ? (
              <DogFilterFields 
                filter={internalFilter}
                setFilter={setInternalFilter}
                appData={appData}
              />
            ) : null 
            }
          </AccordionDetails>            
          <Divider />
          <AccordionActions>
            <Button 
              size="small" 
              onClick={() => setShowFilterOptions(!showFilterOptions)}
            >
              Cancel
            </Button>
            <Button 
              size="small" 
              color="primary"
              onClick={apply}
            >
              Apply
            </Button>
          </AccordionActions>
        </Accordion>
      </div>
    </>
  );
};