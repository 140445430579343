import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { TextField, Button, Link, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Wrapper, LoadingButton } from '../Layout';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn({ authState, setAuthState, params }) {
  const classes = useStyles();
  const { push } = useHistory();

  const initEmail = params.email || authState.email || "";
  const initialErr = params.error || authState.error || "";
  const initialErrMsg = (initialErr === "AlreadyConfirmed") 
    ? "This email address is already confirmed"
    : "";

  const [email, setEmail] = useState(initEmail);
  const [password, setPassword] = useState("");

  const [error, setError] = useState(initialErrMsg);
  const [loading, setLoading] = useState(false);

  const onPasswordChange = async (event) => {
    const password = event.target.value;
    setPassword(password);
  };

  const onEmailChange = async (event) => {
    const email = event.target.value;
    setEmail(email);
  };

  const handleError = (err) => {
    const validCode = [
      "UserNotFoundException",
      "UserNotConfirmedException",
      "NotAuthorizedException",
      "AlreadyConfirmed",
    ].includes(err.code);
    
    console.error(err);
    if (validCode) setError(err.message);
    else throw err;
  };

  const onSubmit = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);
      await signIn(email.toLowerCase(), password);
      setAuthState({
        ...authState,
        status: "signedIn"
      });
      setLoading(false);
      gotoRoot();
    } catch (err) {
      handleError(err);
      setLoading(false);
    }
  };

  const signIn = async (email, password) => {
    return await Auth.signIn(email, password);
  }

  const gotoRoot = _ => {
    push('/');
  };

  const gotoSignUp = _ => {
    push('/auth/signup');
  };

  const gotoForgotPassword = _ => {
    push(`/auth/forgotpassword?email=${email}`);
  };

  return (
    <Wrapper 
      title="Sign In"
      error={error}
    >
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={onEmailChange}
          defaultValue={email}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={onPasswordChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={onSubmit}
          disabled={loading || !email || !password}
        >
          Sign In
          {loading && <LoadingButton />}
        </Button>
        
        <Grid container>
          <Grid item xs>
            <Link onClick={gotoForgotPassword} variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link onClick={gotoSignUp} variant="body2">
              {"No account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </form>
    </Wrapper>
  );
}