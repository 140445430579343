import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { Wrapper } from '../Layout';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
  autoSubmit: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ConfirmSignUp({ authState, setAuthState, params }) {
  const classes = useStyles();
  const { push } = useHistory();

  const initEmail = params.email || authState.email || "";

  const [autoSubmit, setAutoSubmit] = useState(!!initEmail);
  const [showEmail, setShowEmail] = useState(!initEmail);
  const [email, setEmail] = useState(initEmail);

  const [error, setError] = useState("");

  const onEmailChange = async (event) => {
    const email = event.target.value;
    setEmail(email);
  };

  const handleError = (err) => {
    const validCode = [
      "UserNotFoundException",
      "InvalidData",
    ].includes(err.code);

    if (err.code === "UserNotFoundException") {
      err.message = "User not found";
    }
    
    setAutoSubmit(false);
    setShowEmail(true);

    console.error(err);
    if (validCode) {
      setError(err.message);
    } 
    else throw err;
  };

  const validate = (email) => {
    const err = {
      code: "InvalidData",
      message: "An email address must be provided"
    };
    if (!email) {
      handleError(err);
      throw err;
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    validate(email);
    await forgotPassword(email);
  };

  const forgotPassword = async (email) => {
    try {
      await Auth.forgotPassword(email);
      gotoConfirmForgotPassword();
    }
    catch (err) {
      handleError(err);
    }
  }

  const gotoConfirmForgotPassword = _ => {
    push(`/auth/forgotpassword/confirm?email=${email}`);
  };

  useEffect(() => {
    if (autoSubmit) forgotPassword(email);
  }, []);

  const formView = (
    <form className={classes.form} noValidate>
      <Grid container spacing={2}>

        {showEmail && (
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              name="email"
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email"
              autoFocus
              onChange={onEmailChange}
            />
          </Grid>
        )}

      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={onSubmit}
      >
        Confirm Code
      </Button>
    </form>
  );

  const autoSubmitView = (
    <Grid container spacing={3} className={classes.autoSubmit}>
      <Grid container item justify="center">
        We're just emailing you a code!
      </Grid>
      <Grid container item justify="center">
        <CircularProgress />
      </Grid>
    </Grid>
  );
  
  return (
    <Wrapper 
      title="Forgot Password"
      error={error}
    >
      {autoSubmit ? autoSubmitView : formView}
    </Wrapper>
  );
}