import React, { useState } from 'react';

import { Grid, } from '@material-ui/core';

import Dogs from './Dogs';
import Cats from './Cats';

import FilterMenu from '../FilterMenu';

export default function DogList({ appData }) {
  const [dogsOrCats, setDogsOrCats] = useState("dogs");
  const [filter, setFilter] = useState({ 
    sex: [], 
    breed: [],
    age: [],
  });

  return (
    <Grid container spacing={3}>
      <FilterMenu 
        dogsOrCats={dogsOrCats} 
        setDogsOrCats={setDogsOrCats}
        filter={filter}
        setFilter={setFilter}
        appData={appData}
      />
      {dogsOrCats === "dogs" ? (
        <Dogs filter={filter} />
      ) : (
        <Cats filter={filter} />
      )}
    </Grid> 
  );
}
